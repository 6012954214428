import { createSlice, createAsyncThunk, isPending } from '@reduxjs/toolkit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';

export const getMapDetailsActionCreator = createAsyncThunk('vison/map', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.VISION_MAP}${payload.id}`, payload);
  return data;
});

export const getVideoActionCreator = createAsyncThunk('vison/video', async (payload) => {
  const { data } = await fetchUtility(
    'get',
    `${SERVER_URL.VISION_VIDEO}${payload.id}/${payload.mediaId}`,
    payload
  );
  return data;
});

export const getFrameDetailsActionCreator = createAsyncThunk('vison/frame', async (payload) => {
  const queryString = new URLSearchParams(payload).toString();
  const { data } = await fetchUtility(
    'get',
    `${SERVER_URL.VISION_FRAME}${payload.videoId}/${payload.mediaId}/${payload.time}?${queryString}`,
    payload
  );
  return data;
});

export const addAnnotationActionCreator = createAsyncThunk(
  'vison/addAnnotation',
  async (payload) => {
    const { data, status } = await fetchUtility(
      'post',
      `${SERVER_URL.ANNOTATION_ADD}`,
      payload.payload
    );
    return { data, status, payload: payload.frameData };
  }
);

export const updateAnnotationActionCreator = createAsyncThunk(
  'vison/updateAnnotation',
  async (payload) => {
    const { data, status } = await fetchUtility(
      'put',
      `${SERVER_URL.ANNOTATION_UPDATE}`,
      payload.payload
    );
    return { data, status, payload: payload.frameData };
  }
);

export const deleteAnnotationActionCreator = createAsyncThunk(
  'vison/deleteAnnotation',
  async (payload) => {
    const { data, status } = await fetchUtility(
      'delete',
      `${SERVER_URL.ANNOTATION_DELETE}${payload.payload}`
    );
    return { data, status, payload: payload.frameData };
  }
);

export const deleteDetectedAnnotationActionCreator = createAsyncThunk(
  'vison/deleteDetectedAnnotation',
  async (payload) => {
    const { data, status } = await fetchUtility(
      'delete',
      `${SERVER_URL.DETECTED_ANNOTATION_DELETE}${payload.payload}`
    );
    return { data, status, payload: payload.frameData };
  }
);

export const getClassItemDataActionCreator = createAsyncThunk(
  'vison/classItemDataAnnotation',
  async (payload) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ANNOTATION_CLASS_ITEM_DATA}/${payload.assetType}`,
      payload
    );
    return data;
  }
);

const isPendingAction = isPending(
  getMapDetailsActionCreator,
  getVideoActionCreator
  // getFrameDetailsActionCreator
);

const isPendingActionOverLay = isPending(
  addAnnotationActionCreator,
  updateAnnotationActionCreator,
  deleteAnnotationActionCreator,
  deleteDetectedAnnotationActionCreator
);

export const visionSlice = createSlice({
  name: 'vision',
  initialState: {
    mapData: null,
    videoData: null,
    frameData: null,
    classItemList: [],
    messageData: null,
    loading: null,
    loadingOverlay: null,
    error: null
  },
  reducers: {
    clearMessage: {
      reducer: (state) => {
        state.messageData = null;
      }
    },
    clearclassItemList: {
      reducer: (state) => {
        state.classItemList = [];
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMapDetailsActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.mapData = action?.payload;
    });
    builder.addCase(getVideoActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.videoData = action?.payload;
      state.error = null;
    });
    builder.addCase(getFrameDetailsActionCreator.fulfilled, (state, action) => {
      // state.loading = false;
      if (!state.frameData) {
        state.frameData = action?.payload;
      }
    });
    builder.addCase(getClassItemDataActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.classItemList = [...action.payload.data, ...state.classItemList];
    });
    builder.addCase(addAnnotationActionCreator.fulfilled, (state, action) => {
      state.frameData = action?.payload?.payload;
      state.loadingOverlay = false;
      state.messageData = {
        message: action?.payload?.data?.message,
        status: action?.payload?.status
      };
    });
    builder.addCase(updateAnnotationActionCreator.fulfilled, (state, action) => {
      state.loadingOverlay = false;
      state.messageData = {
        message: action?.payload?.data?.message,
        status: action?.payload?.status
      };
    });
    builder.addCase(deleteAnnotationActionCreator.fulfilled, (state, action) => {
      state.frameData = action?.payload?.payload;
      state.loadingOverlay = false;
      state.messageData = {
        message: action?.payload?.data?.message,
        status: action?.payload?.status
      };
    });
    builder.addCase(deleteDetectedAnnotationActionCreator.fulfilled, (state, action) => {
      state.frameData = action?.payload?.payload;
      state.loadingOverlay = false;
      state.messageData = {
        message: action?.payload?.data?.message,
        status: action?.payload?.status
      };
    });
    builder.addMatcher(isPendingActionOverLay, (state) => {
      state.loadingOverlay = true;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.loadingOverlay = false;
        state.error = action.error.message;
      }
    );
  }
});

export const {
  clearMessage: clearMessageVisionActionCreator,
  clearclassItemList: clearClassItemListVisionActionCreator
} = visionSlice.actions;

export default visionSlice.reducer;
