import React, { useEffect, Suspense, lazy, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import AWS from 'aws-sdk';
import {
  Authenticator,
  useTheme,
  Heading,
  useAuthenticator,
  View,
  translations,
  Button
} from '@aws-amplify/ui-react';

import { I18n, Auth, Hub } from 'aws-amplify';
import Cookie from 'js-cookie';
import { Provider } from 'react-redux';
import store from './redux/store';
import Header from './frontend/layout/header';
import AdminHeader from './admin/layout/Header/Header';
import Footer from './frontend/layout/footer';
import translatedData from './utils/i18n';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import './App.scss';
import Error from './admin/components/Error/Error';
import Loader from './admin/components/Loader/Loader';
import ScrollToTop from './admin/components/ScrollToTop/ScrollToTop';
import ErrorBoundary from './admin/components/Error/ErrorComponent';

I18n.putVocabularies(translations);
I18n.setLanguage('en');

I18n.putVocabularies({
  en: translatedData
});

const PublicUrl = lazy(() => import('./admin/pages/publicUrl/PublicUrl'));
const Home = lazy(() => import('./admin/pages/home/Home'));
const Intel = lazy(() => import('./admin/pages/intel/Intel'));
const DeleteFrames = lazy(() => import('./admin/pages/intel/deleteFrames/DeleteFrames'));
const ProjectMap = lazy(() => import('./admin/pages/vision/projectMap/ProjectMap'));
const Order = lazy(() => import('./admin/pages/order/Order'));
// const ProjectFootage = lazy(() => import('./admin/pages/vision/projectFootage/ProjectFootage'));
const ProjectFootageUpdated = lazy(() =>
  import('./admin/pages/vision/projectFootageUpdated/ProjectFootageUpdated')
);
const Users = lazy(() => import('./admin/pages/users/Users'));
const CreateProject = lazy(() => import('./admin/pages/createproject/CreateProject'));
const AssetGroups = lazy(() => import('./admin/pages/assetGroups/AssetGroups'));
const UserProject = lazy(() => import('./admin/pages/users/UserProject'));

const components = {
  Header: () => {
    const { route } = useAuthenticator((context) => [context.route]);
    if (route === 'authenticated') {
      localStorage.removeItem('route');
    }

    useEffect(() => {
      const currentRoute = localStorage.getItem('route');
      if (route !== 'authenticated' && !currentRoute) {
        localStorage.setItem('route', route);
      }
    }, []);

    useEffect(() => {
      if (route !== 'authenticated') {
        localStorage.setItem('route', route);
      }
    }, [route]);

    return <Header />;
  },
  Footer,
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Login
        </Heading>
      );
    }
  },
  ConfirmResetPassword: {
    Footer() {
      const { toSignIn } = useAuthenticator();
      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
            className="signinback">
            Back to Login
          </Button>
        </View>
      );
    }
  },
  ForceNewPassword: {
    Footer() {
      const { toSignIn } = useAuthenticator();
      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
            className="signinback">
            Back to Login
          </Button>
        </View>
      );
    }
  }
};

const formFields = {
  signIn: {
    username: {
      type: 'email',
      placeholder: 'Email Address',
      isRequired: true
    }
  },
  resetPassword: {
    username: {
      placeholder: 'Email Address'
    }
  },
  forceNewPassword: {
    password: {
      order: 1
    },
    given_name: {
      order: 3,
      placeholder: 'Given Name'
    },
    family_name: {
      order: 4,
      placeholder: 'Family Name'
    },
    confirm_password: {
      order: 2
    },
    middle_name: {
      order: 5,
      placeholder: 'Middle Name'
    }
  }
};

const { pathname } = window.location;
const firstSegment = pathname.split('/')[1];

function App() {
  const [initialState, setInitState] = useState('signIn');
  const [loading, setLoading] = useState(true);
  const [s3, setS3] = useState(null);

  const projectName = 'infra ai';
  const environment = process.env.REACT_APP_ENV;

  useEffect(() => {
    document.title = projectName + (environment ? ` - ${environment}` : '');
  }, [projectName, environment]);

  const initiateS3 = () => {
    Auth.currentSession().then((userSession) => {
      const { jwtToken } = userSession.getIdToken();
      const key = `cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_POOL_ID}`;
      const identityPoolData = new AWS.CognitoIdentityCredentials(
        {
          IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
          Logins: {
            [key]: jwtToken
          }
        },
        {
          region: process.env.REACT_APP_AWS_REGION
        }
      );

      identityPoolData.get(() => {
        const { accessKeyId, secretAccessKey, sessionToken } = identityPoolData;
        if (!accessKeyId) {
          window.location.reload();
        }
        const s3Instance = new AWS.S3({ accessKeyId, secretAccessKey, sessionToken });
        setS3(s3Instance);
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    const currentRoute = localStorage.getItem('route');
    setInitState(currentRoute);
    const updateUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          initiateS3();
        }
      } catch {
        setLoading(false);
      }
    };
    Hub.listen('auth', updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser); // cleanup
  }, []);

  // Auth.currentSession().then((res) => {
  //   const accessToken = res.getAccessToken();
  //   const jwt = accessToken.getJwtToken();
  //   Cookie.set('token', jwt);
  // });

  if (firstSegment === 'public-url') {
    return (
      <Provider store={store}>
        <Router>
          <Box className="publicUrl-container">
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route exact path="/public-url/footage/:id" element={<PublicUrl />} />
              </Routes>
            </Suspense>
          </Box>
        </Router>
      </Provider>
    );
  }
  return loading ? (
    <Loader />
  ) : (
    <Provider store={store}>
      <Authenticator
        className="login__parent-wrapper"
        components={components}
        formFields={formFields}
        initialState={initialState}
        hideSignUp>
        {({ user }) => {
          const accessToken = user.signInUserSession.getAccessToken();
          const jwt = accessToken.getJwtToken();
          Cookie.set('token', jwt);
          return (
            <Router>
              <ScrollToTop />
              <Box className="parent-container">
                <AdminHeader user={user} />
                <Box className="infai_fill_width_grid ">
                  <Suspense fallback={<Loader />}>
                    <ErrorBoundary>
                      <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/home" element={<Home />} />
                        <Route exact path="/myprojects" element={<Home />} />
                        <Route exact path="/deletedprojects" element={<Home />} />
                        <Route exact path="/projects-requests" element={<Home />} />
                        <Route exact path="/users" element={<Users />} />
                        {/* <Route exact path="/marketplace" element={<Home />} /> */}

                        <Route exact path="/intel/:id" element={<Intel />} />
                        <Route exact path="/intel-description/:id" element={<Intel />} />
                        <Route exact path="/intel-timeline/:id" element={<Intel />} />
                        <Route exact path="/intel-award/:id" element={<Intel />} />
                        <Route exact path="/intel-routes/:id" element={<Intel />} />
                        {/* <Route exact path="/intel-analysis/:id" element={<Intel />} /> */}
                        <Route exact path="/intel-contacts/:id" element={<Intel />} />
                        {/* <Route exact path="/intel-marketplace/:id" element={<Intel />} /> */}
                        <Route exact path="/intel-users-access/:id" element={<Intel />} />
                        <Route exact path="/intel-delete-frames/:id" element={<DeleteFrames />} />

                        <Route exact path="/order/:id" element={<Order />} />
                        <Route exact path="/order-inventory/:id" element={<Order />} />
                        <Route exact path="/order-condition/:id" element={<Order />} />
                        <Route exact path="/inventory/:id" element={<Order />} />
                        <Route exact path="/condition/:id" element={<Order />} />

                        <Route exact path="/vision/:id" element={<ProjectMap />} />
                        <Route exact path="/project-details" element={<UserProject />} />
                        <Route exact path="/create-project" element={<CreateProject />} />

                        <Route exact path="/assets-road-side" element={<AssetGroups />} />
                        <Route exact path="/assets-side-walk" element={<AssetGroups />} />
                        <Route
                          exact
                          path="/footage/:id"
                          // element={<ProjectFootage s3Instance={s3} />}
                          element={<ProjectFootageUpdated s3Instance={s3} />}
                        />

                        <Route exact path="/error" element={<Error />} />
                      </Routes>
                    </ErrorBoundary>
                  </Suspense>
                </Box>
              </Box>
            </Router>
          );
        }}
      </Authenticator>
    </Provider>
  );
}

export default App;
