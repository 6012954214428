import { createSlice, createAsyncThunk, isPending } from '@reduxjs/toolkit';
import fetchUtility from '../fetchUtility';
import { SERVER_URL } from '../../admin/constants/Constants';

export const getMapView = createAsyncThunk('map/view', async (payload) => {
  const { data } = await fetchUtility('get', `${SERVER_URL.MAP_VIEW}${payload.id}`);
  return data;
});

export const updateMapCoordinates = createAsyncThunk('map/update', async (payload) => {
  const { data } = await fetchUtility(
    'put',
    `${SERVER_URL.UPDATE_COORDINATES}${payload.id}/${payload.mediaId}/${payload.frameId}/${payload.tagId}`,
    payload.data
  );
  return data;
});

const isPendingAction = isPending(getMapView);

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
    mapData: null,
    loading: null,
    error: null,
    isFilterObjectsOpen: false,
    conditionReportFilterValue: null
  },
  reducers: {
    toggleFilterObjects: {
      reducer: (state, action) => {
        state.isFilterObjectsOpen = action.payload;
      }
    },
    conditionReportFilter: {
      reducer: (state, action) => {
        state.conditionReportFilterValue = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMapView.fulfilled, (state, action) => {
      state.loading = false;
      state.mapData = action?.payload;
    });
    builder.addCase(updateMapCoordinates.fulfilled, (state, action) => {
      state.loading = false;
      state.updateData = action?.payload;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.loading = true;
    });
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.mapData = null;
      }
    );
  }
});

export const {
  toggleFilterObjects: toggleFilterObjectsActionCreator,
  conditionReportFilter: conditionReportFilterActionCreator
} = mapSlice.actions;

export default mapSlice.reducer;
