const BASIC_URL = process.env.REACT_APP_SERVER_URL;

export const SERVER_URL = {
  PROJECT_LIST: `${BASIC_URL}/projects`,
  PROJECT_MEDIA: `${BASIC_URL}/projects/project-media`,
  PROJECT_COR_FRAME: `${BASIC_URL}/projects/project-cordinate-frames`,
  DELETED_PROJECT_LIST: `${BASIC_URL}/projects/deleted`,
  DELETE_PROJECT: `${BASIC_URL}/projects/delete`,
  REMOVE_PROJECT: `${BASIC_URL}/projects/permanently-delete`,
  RESTORE_PROJECT: `${BASIC_URL}/projects/restore`,
  MY_PROJECT_LIST: `${BASIC_URL}/projects/myprojects`,
  MY_PROJECT_ADD: `${BASIC_URL}/projects/myprojects/add/`,
  MY_PROJECT_REMOVE: `${BASIC_URL}/projects/myprojects/remove/`,
  ORDER_UPDATE_STATUS: `${BASIC_URL}/projects/update-status`,
  REPORT_REQUESTED: `${BASIC_URL}/projects/projects-report-requested`,
  PROJECT_REPORT_USER: `${BASIC_URL}/projects/projects-report-user`,
  PROJECT_BATCHING_LAMNDA: `${BASIC_URL}/projects/batching-lamnda`,
  PROJECT_DELETE_ANOTATION: `${BASIC_URL}/projects/delete-frame-anotation`,
  REORDER_ROUTE: `${BASIC_URL}/projects/reorder-route`,

  COUNTRY_LIST: `${BASIC_URL}/home/district`,
  STATE_LIST: `${BASIC_URL}/home/state`,

  INTEL_SUMMARY: `${BASIC_URL}/intel/summary/`,
  INTE_DESCRIPTION: `${BASIC_URL}/intel/description/`,
  INTEL_TIMELINE: `${BASIC_URL}/intel/timeline/`,
  INTEL_AWARD: `${BASIC_URL}/intel/award/`,
  INTEL_ANALYSIS: `${BASIC_URL}/intel/analysis/`,
  INTEL_CONTACT: `${BASIC_URL}/intel/contacts/`,

  VISION_MAP: `${BASIC_URL}/vision/gpx/`,
  VISION_VIDEO: `${BASIC_URL}/vision/media/video/`,
  VISION_FRAME: `${BASIC_URL}/vision/media/frame/`,

  ANNOTATION_ADD: `${BASIC_URL}/vision/annotations/add`,
  ANNOTATION_UPDATE: `${BASIC_URL}/vision/annotations/update`,
  ANNOTATION_DELETE: `${BASIC_URL}/vision/annotations/delete/`,
  DETECTED_ANNOTATION_DELETE: `${BASIC_URL}/vision/detections/delete/`,
  ANNOTATION_CLASS_ITEM_DATA: `${BASIC_URL}/vision/asset-classes`,

  ORDER_RAW_DATA: `${BASIC_URL}/order/rawdata/`,
  ORDER_INVENTORY_DATA: `${BASIC_URL}/order/inventory-report/`,
  ORDER_CONDITIONAL_DATA: `${BASIC_URL}/order/conditional-report/`,
  ORDER_STATUS: `${BASIC_URL}/order/status/`,
  PROJECT_STATUSES: `${BASIC_URL}/order/user-status`,
  USER_PROJECTS: `${BASIC_URL}/order/user-project-status`,

  INVENTORY_REPORT: `${BASIC_URL}/reports/inventory/`,
  CONDITION_REPORT: `${BASIC_URL}/reports/conditional/`,
  UPDATE_CONDITION_REPORT: `${BASIC_URL}/reports/conditional/update/`,
  UPDATE_INVENTORY_REPORT: `${BASIC_URL}/reports/inventory/update/`,
  ORDER_INVENTORY_REPORT_DOWNLOAD: `${BASIC_URL}/reports/inventory/`,
  ORDER_CONDITION_REPORT_DOWNLOAD: `${BASIC_URL}/reports/conditional/`,

  MAP_VIEW: `${BASIC_URL}/vision/coordinate-data/`,
  UPDATE_COORDINATES: `${BASIC_URL}/vision/coordinates/update/`,

  USER_LIST: `${BASIC_URL}/user`,

  ASSET_DETAILS: `${BASIC_URL}/assets`,
  ADD_ASSET_GROUP: `${BASIC_URL}/assets/addGroup`,
  ADD_ASSET_CLASS: `${BASIC_URL}/assets/addClass`,
  ADD_ASSET_OBJECT: `${BASIC_URL}/assets/addObejct`,
  ADD_OBJECT_ATTRIBUTE: `${BASIC_URL}/assets/addObejctAttr`,
  UPDATE_OBJECT_ATTRIBUTE: `${BASIC_URL}/assets/updateObjectAttr`,
  DELETE_OBJECT_ATTRIBUTE: `${BASIC_URL}/assets/deleteObjectAttr`,
  UPDATE_ASSET_GROUP: `${BASIC_URL}/assets/updateGroup`,
  DELETE_ASSET_GROUP: `${BASIC_URL}/assets/deleteGroup`,
  UPDATE_ASSET_CLASS: `${BASIC_URL}/assets/updateClass`,
  DELETE_ASSET_CLASS: `${BASIC_URL}/assets/deleteClass`,
  DELETE_ASSET_ITEM: `${BASIC_URL}/assets/deleteItem`,
  UPDATE_ASSET_GROUP_ALL_HIDE: `${BASIC_URL}/assets/updateAllGroupActive`,
  UPDATE_ASSET_GROUP_HIDE: `${BASIC_URL}/assets/updateGroupActive`,
  UPDATE_ASSET_CLASSES_ALL_HIDE: `${BASIC_URL}/assets/updateAllClassActive`,
  UPDATE_ASSET_CLASSES_HIDE: `${BASIC_URL}/assets/updateClassActive`
};

export const CONDITION_RATING = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const STATUSES = ['Capturing', 'Inventorying', 'Accessing'];

export const visionMapRoutes = (projectId, data) => [
  {
    path: `/footage/${projectId}`,
    name: 'View Footage',
    view: data?.order_rawdata === 'available',
    key: 'footage'
  },
  {
    path: `/inventory/${projectId}`,
    name: 'View Inventory Report',
    view: data?.order_inventory === 'available',
    key: 'inventory'
  },
  {
    path: `/condition/${projectId}`,
    name: 'View Condition Report',
    view: data?.order_condition === 'available',
    key: 'conditional'
  },
  {
    path: `/order/${projectId}`,
    name: 'Order Raw Data',
    view: data?.order_rawdata === 'unavailable',
    key: 'order-raw'
  },
  {
    path: `/order-inventory/${projectId}`,
    name: 'Order Inventory Report',
    view: data?.order_inventory === 'unavailable',
    key: 'order-inventory'
  },
  {
    path: `/order-condition/${projectId}`,
    name: 'Order Condition Report',
    view: data?.order_condition === 'unavailable',
    key: 'order-conditioin'
  },
  {
    path: `/vision/${projectId}`,
    name: 'Object Summary',
    view: true,
    key: 'object-summary'
  },
  {
    path: `/intel-routes/${projectId}`,
    name: 'Uploads',
    view: true,
    key: 'intel-routes'
  },
  {
    path: `/intel-delete-frames/${projectId}`,
    name: 'Frames',
    view: true,
    key: 'intel-delete-frames'
  }
];

export const intelOrderMapRoute = (projectId) => [
  {
    path: `/vision/${projectId}`,
    name: 'Full View'
  }
];

export const orderReportStatus = {
  request: 'Request',
  requested: 'Requested',
  purchase: 'Purchase',
  inprocess: 'In Process',
  purchased: 'Purchased'
};
export const orderReportAvailabilityStatus = {
  available: 'Available',
  unavailable: 'Unavailable'
};

export const USER_ROLES = {
  SUPER_ADMIN: 'super-admin',
  CIVIL_ENGINEER: 'civil-engineer',
  ASSET_MANAGER: 'asset-manager',
  ADMIN: 'admin'
};

export const NEW_USER_ROLES = {
  ADMIN: 'Admin',
  CIVIL_ENGINEER: 'CE',
  VIEWER: 'Viewer'
};

export const ERROR_MSGS = {
  401: 'Session Expired!!.Please try to logout and login again.',
  403: 'Project not found. Please return to Home Page.',
  other: 'Something went wrong. Please reload.'
};

export const STATES_DISTRICTS = {
  Virginia: [
    'Bristol',
    'Salem',
    'Lynchburg',
    'Richmond',
    'Hampton Roads',
    'Fredericksburg',
    'Culpeper',
    'Staunton',
    'Northern Virginia'
  ],
  Florida: [
    'Florida 1',
    'Florida 2',
    'Florida 3',
    'Florida 4',
    'Florida 5',
    'Florida 6',
    'Florida 7',
    'Three'
  ]
};

export const ROLE_PERMISSIONS = {
  [NEW_USER_ROLES.ADMIN]: {
    permissions: [
      'create_user',
      'add_annotation',
      'update_annotation',
      'delete_annotation',
      'view_inventory',
      'update_inventory',
      'view_conditional',
      'update_conditional',
      'delete_project',
      'delete_user',
      'edit_project',
      'create_project'
    ]
  },
  [NEW_USER_ROLES.CIVIL_ENGINEER]: {
    permissions: [
      'add_annotation',
      'update_annotation',
      'delete_annotation',
      'view_inventory',
      'update_inventory',
      'view_conditional',
      'update_conditional'
    ]
  },
  [NEW_USER_ROLES.VIEWER]: {
    permissions: ['view_conditional', 'view_inventory', 'view_annotation']
  }
};

export const PERMISSIONS = {
  CREATE_USER: 'create_user',
  ADD_ANNOTATION: 'add_annotation',
  UPDATE_ANNOTATION: 'update_annotation',
  DELETE_ANNOTATION: 'delete_annotation',
  VIEW_INVENTORY: 'view_inventory',
  UPDATE_INVENTORY: 'update_inventory',
  VIEW_CONDITIONAL: 'view_conditional',
  UPDATE_CONDITIONAL: 'update_conditional',
  VIEW_ANNOTATION: 'view_annotation',
  DELETE_PROJECT: 'delete_project',
  DELETE_USER: 'delete_user',
  EDIT_PROJECT: 'edit_project',
  CREATE_PROJECT: 'create_project'
};

export const ERROR_MESSAGES = {
  ASSET_TYPE: 'Asset Type is required',
  REQUIRED: 'This field is required',
  DUPLICATE: 'Name already exists!'
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

export const MODAL_CONTENT = {
  DELETE: {
    title: 'Are you sure you want to delete',
    message: ' from your projects list?',
    button: 'Delete'
  },
  REMOVE: {
    title: 'Are you sure you want to remove',
    message: ' from your deleted projects list?',
    button: 'Remove'
  },
  RESTORE: {
    title: 'Are you sure you want to restore',
    message: ' from your deleted projects list?',
    button: 'Restore'
  }
};
